import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { VerticalCardProps } from './type';

import { ThumbnailImage } from '@/components/ui';

export const VerticalCard = ({
  imageSrc,
  imageSize,
  title,
  topSection,
  bottomSection,
  priority = false,
  titleSize,
  spTitleSize,
}: VerticalCardProps) => {
  return (
    <article>
      <ThumbnailImage
        src={imageSrc}
        alt=""
        rounded="md"
        priority={priority}
        size={imageSize}
      />

      {topSection !== undefined && (
        <div className={clsx('tw-mt-2')}>{topSection}</div>
      )}

      <H
        className={clsx(
          `tw-mt-2 tw-text-title${
            spTitleSize ?? titleSize
          } tw-font-bold tw-line-clamp-2`,
          `md:tw-text-title${titleSize}`
        )}
      >
        {title}
      </H>

      {bottomSection !== undefined && (
        <div className={clsx('tw-mt-2')}>{bottomSection}</div>
      )}
    </article>
  );
};
