import { clsx } from 'clsx';

import type { NavigationButtonProps } from './type';

export const NavigationButton = ({
  className,
  onClick,
}: NavigationButtonProps) => {
  return (
    <button
      className={clsx(
        'tw-w-8 tw-h-[72px] tw-cursor-pointer tw-select-none',
        'tw-bg-gray-100 tw-flex tw-items-center tw-justify-center',
        className
      )}
      onClick={onClick}
    >
      <span
        className={clsx(
          'tw-w-2 tw-h-2 tw-border-b-2 tw-border-black tw-rounded-[1px]'
        )}
      />
    </button>
  );
};
