export const adjustPositions = (positions: number[]) =>
  positions.map((i) => {
    const len = positions.length;
    if (i <= len / -2) return i + len;
    if (i > len / 2) return i - len;
    return i;
  });

export const extendBanners = <T>(banners: T[]) => {
  const len = banners.length;
  if (len >= 5) return banners;
  if (len >= 3) return [...banners, ...banners];
  if (len >= 2) return [...banners, ...banners, ...banners];
  return [...banners, ...banners, ...banners, ...banners, ...banners];
};
