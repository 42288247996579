import { clsx } from 'clsx';

import type { SlideProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import { AnchorLink, ThumbnailImage } from '@/components/ui';
import { isExternal } from '@/utils/features/banner';

export const Slide = ({ banner, disabled }: SlideProps) => {
  if (!banner.url) return null;
  if (!banner.thumbnail?.file?.url) return null;
  return (
    <AnchorLink
      {...bannerAttributes({ id: banner.id, type: banner.type })}
      href={banner.url}
      target={isExternal(banner.url) ? '_blank' : '_self'}
      disabled={disabled}
    >
      <ThumbnailImage
        src={banner.thumbnail.file.url}
        alt={banner.lead ?? ''}
        aspectRatio="19/10"
        size={{
          sp: '600px',
          tablet: '700px',
          pc: '760px',
        }}
        objectFit="cover"
        priority
      />
      <div
        className={clsx(
          'tw-w-full tw-py-4 tw-text-body16 tw-font-bold tw-text-center tw-text-black tw-truncate',
          'tw-transition-opacity tw-duration-75',
          disabled && 'tw-opacity-0'
        )}
      >
        {banner.lead}
      </div>
    </AnchorLink>
  );
};
