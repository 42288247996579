import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { HorizontalCardProps } from './type';

import { Image } from '@/components/ui';

export const HorizontalCard = ({
  title,
  imageSrc,
  imageSize,
  bottomSection,
  titleSize,
  spTitleSize,
  priority = false,
}: HorizontalCardProps) => {
  return (
    <article className={clsx('tw-flex tw-gap-4')}>
      <div
        className={clsx(
          'tw-w-[89px] tw-h-[58px] tw-rounded-md tw-overflow-hidden'
        )}
      >
        <Image src={imageSrc} alt="" size={imageSize} priority={priority} />
      </div>
      <div className={clsx('tw-flex-1 tw-flex tw-flex-col tw-justify-between')}>
        <H
          className={clsx(
            `tw-text-title${
              spTitleSize ?? titleSize
            } md:tw-text-title${titleSize} tw-font-bold tw-line-clamp-2`
          )}
        >
          {title}
        </H>
        {bottomSection !== undefined && bottomSection}
      </div>
    </article>
  );
};
