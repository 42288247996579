import type { TopProps } from '@/components/pages/Top/type';
import type { GetStaticProps, NextPage } from 'next';

import { TopPage } from '@/components/pages/Top';
import { TopPageDocument } from '@/graphql/generated';
import { initializeApollo } from '@/libs/apollo';

const Page: NextPage<TopProps> = (props) => {
  return TopPage(props);
};

export const getStaticProps: GetStaticProps<TopProps> = async () => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query({
    query: TopPageDocument,
  });

  return {
    props: { data },
    revalidate: 60,
  };
};

export default Page;
