import { NextSeo } from 'next-seo';

import { SITE } from '@/constants';

export const Seo = () => {
  const title = SITE.CATCH_COPY;
  const url = SITE.DOMAIN;
  const description = SITE.DESCRIPTION;
  const image = {
    url: `${SITE.DOMAIN}${SITE.PUBLISHER.LOGO_URL}`,
    width: 148,
    height: 148,
  };

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description,
          type: 'website',
          images: [image],
        }}
        twitter={{
          site: SITE.TWITTER.SITE,
          handle: SITE.TWITTER.DOMAIN,
          cardType: 'summary',
        }}
      />
    </>
  );
};
