import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { QAListProps } from './type';

import { IconQuestion } from '@/components/icons';
import { Image, AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';

export const QAList = ({ articles }: QAListProps) => {
  return (
    <div
      className={clsx(
        'tw-grid tw-grid-rows-[auto_auto] tw-gap-3',
        'md:tw-grid-rows-1 md:tw-grid-cols-12 md:tw-gap-5 md:tw-items-center'
      )}
    >
      <div
        className={clsx('tw-rounded-md tw-overflow-hidden', 'md:tw-col-span-5')}
      >
        <Image
          src="/images/qa_thumbnail.png"
          alt="面倒な調べ物を一括解決！公募Q&A"
          objectFit="contain"
        />
      </div>
      <ul className={clsx('md:tw-col-span-7')}>
        {articles
          .flatMap((a) => (a.id ? { ...a, id: a.id } : []))
          .map((article) => (
            <li key={article.id}>
              <AnchorLink href={ROUTE.articleDetail(article.id)}>
                <div
                  className={clsx(
                    'tw-flex tw-items-center tw-py-3 tw-space-x-3',
                    'tw-border-b tw-border-gray-200'
                  )}
                >
                  <IconQuestion boxSize="1.5em" />
                  <H className={clsx('tw-flex-1 tw-line-clamp-1')}>
                    {article.listView?.lead ?? article.title}
                  </H>
                </div>
              </AnchorLink>
            </li>
          ))}
      </ul>
    </div>
  );
};
