import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { MainSliderProps } from './type';

import { MainSliderSlideBannerSuperBannersFragmentDoc } from '@/graphql/generated';

import { NavigationButton } from './NavigationButton';
import { Slide } from './Slide';
import { SWIPE_INTERVAL, SWIPE_THRESHOLD } from './const';
import { adjustPositions, extendBanners } from './util';

export const MainSlider = ({
  slideWidthRatio,
  spaceBetween,
  ...props
}: MainSliderProps) => {
  const [touchStartX, setTouchStartX] = useState(0);
  const [swipeDiffX, setSwipeDiffX] = useState(0);
  const [isTouched, setIsTouched] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef<NodeJS.Timer | undefined>();
  const sliderRef = useRef<HTMLDivElement>(null);
  const slideWidth = (sliderRef.current?.offsetWidth ?? 0) * slideWidthRatio;

  const [banners] = useState(extendBanners(props.banners));

  const [positions, setPositions] = useState(
    adjustPositions(banners.map((_, i) => i))
  );

  const handlePrev = useCallback(() => {
    setPositions(adjustPositions(positions.map((i) => i + 1)));
    setProgress(0);
  }, [positions]);

  const handleNext = useCallback(() => {
    setPositions(adjustPositions(positions.map((i) => i - 1)));
    setProgress(0);
  }, [positions]);

  const handleTouchStart = (e: React.TouchEvent) => {
    document.body.style.overflow = 'hidden';
    setIsTouched(true);
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const currentX = e.touches[0].clientX;
    setSwipeDiffX(currentX - touchStartX);
  };

  const handleTouchEnd = () => {
    document.body.style.overflow = 'auto';
    setIsTouched(false);
    setSwipeDiffX(0);
    if (swipeDiffX < -SWIPE_THRESHOLD * slideWidth) handleNext();
    if (SWIPE_THRESHOLD * slideWidth < swipeDiffX) handlePrev();
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (isHovered || isTouched) return;
      if (progress < 100) setProgress(progress + 1000 / SWIPE_INTERVAL);
      if (progress >= 100) handleNext();
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [handleNext, isHovered, isTouched, progress]);

  return (
    <div
      ref={sliderRef}
      className={clsx('tw-group tw-relative tw-w-full tw-overflow-x-hidden')}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {banners.map((banner, index) => {
        const position = positions.at(index) ?? 0;
        return (
          <div
            key={index}
            className={clsx(
              position === 0 ? 'tw-relative' : 'tw-absolute',
              'tw-inset-0',
              !isTouched &&
                [-1, 0, 1].includes(position) &&
                'tw-transition tw-transform tw-duration-300'
            )}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              width: `${slideWidthRatio * 100}%`,
              transform: `translateX(calc(${position} * (100% + ${spaceBetween}px) + ${swipeDiffX}px + (1 / ${slideWidthRatio} - 1) * 50%))`,
            }}
          >
            <Slide
              banner={filter(
                MainSliderSlideBannerSuperBannersFragmentDoc,
                banner
              )}
              disabled={position !== 0}
            />
          </div>
        );
      })}
      <div
        className={clsx(
          'tw-hidden group-hover:tw-flex tw-justify-between tw-absolute tw-w-full tw-top-1/2 tw-z-docked -tw-translate-y-1/2',
          'tw-pointer-events-none child:tw-pointer-events-auto -tw-mt-8'
        )}
      >
        <NavigationButton
          className={clsx(
            'child:tw-border-l-2 child:tw-translate-x-1/4 child:tw-rotate-45'
          )}
          onClick={handlePrev}
        />
        <NavigationButton
          className={clsx(
            'child:tw-border-r-2 child:-tw-translate-x-1/4 child:-tw-rotate-45'
          )}
          onClick={handleNext}
        />
      </div>
      <div className={clsx('tw-flex tw-gap-3 tw-px-spContentSide md:tw-px-0')}>
        {props.banners.map(({ id }, index) => (
          <div
            key={index}
            className={clsx(
              'tw-w-full tw-h-1.5 tw-bg-gray-200 tw-rounded-full tw-overflow-hidden'
            )}
          >
            <div
              className={clsx('tw-h-full tw-bg-primary-500')}
              style={{
                width:
                  banners.at(positions.findIndex((p) => p === 0))?.id === id
                    ? `${progress}%`
                    : 0,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
