import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';

import type { ArticleVerticalCardSliderProps } from './type';

import { ArticleVerticalCard } from '@/components/features/article';
import { CardSlider } from '@/components/ui';
import { ArticleVerticalCardArticlesFragmentDoc } from '@/graphql/generated';

export const ArticleVerticalCardSlider = ({
  articles,
  ...props
}: ArticleVerticalCardSliderProps) => {
  return (
    <CardSlider
      items={articles.map((article) => (
        <div key={article.id} className={clsx('tw-rounded-md')}>
          <ArticleVerticalCard
            {...props}
            article={filter(ArticleVerticalCardArticlesFragmentDoc, article)}
          />
        </div>
      ))}
      spView={2.3}
      spSpaceBetween={10}
      view={4}
      spaceBetween={20}
    />
  );
};
