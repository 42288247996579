import type { ArticleTags } from '@/graphql/generated';

import { isWithinPastDays } from '@/utils/date';

export const checkIsNewArticle = (publishedAt: string) =>
  isWithinPastDays(publishedAt, 7);

export const findArticleTagId = (
  tags: Pick<ArticleTags, 'id' | 'name'>[] | undefined | null,
  tagName: string
) => {
  if (tags == null) return undefined;
  return tags.find((t) => t.name === tagName)?.id;
};
