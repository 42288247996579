import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { FooterBannerProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import { AnchorLink, DeadlineLabel, Image, PRLabel } from '@/components/ui';
import { NO_IMAGE_SRC } from '@/constants';
import { isExternal } from '@/utils/features/banner';

export const FooterBanner = ({ banners }: FooterBannerProps) => {
  return (
    <ul
      className={clsx(
        'tw-grid tw-grid-cols-[auto] tw-gap-5',
        'md:tw-grid-cols-[auto_auto]'
      )}
    >
      {banners
        .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
        .map((banner) => (
          <li key={banner.id}>
            <AnchorLink
              {...bannerAttributes({ id: banner.id, type: banner.type })}
              href={banner.url}
              target={isExternal(banner.url) ? '_blank' : '_self'}
            >
              <article className={clsx('tw-flex tw-gap-4')}>
                <div
                  className={clsx(
                    'tw-w-[89px] tw-h-[58px] tw-rounded-md tw-overflow-hidden'
                  )}
                >
                  <Image
                    src={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                    alt=""
                    size={{
                      sp: '90px',
                      tablet: '90px',
                      pc: '90px',
                    }}
                  />
                </div>
                <div
                  className={clsx(
                    'tw-flex-1 tw-flex tw-flex-col tw-justify-between'
                  )}
                >
                  <H
                    className={clsx(
                      'tw-text-title12 tw-font-bold tw-line-clamp-2'
                    )}
                  >
                    {banner.lead}
                  </H>
                  <div className={clsx('tw-flex tw-items-center tw-gap-x-1')}>
                    {isExternal(banner.url) && <PRLabel />}
                    {banner.deadline && (
                      <DeadlineLabel deadlineDate={new Date(banner.deadline)} />
                    )}
                  </div>
                </div>
              </article>
            </AnchorLink>
          </li>
        ))}
    </ul>
  );
};
