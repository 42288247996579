import type { AspectRatio, ROUND_SIZE } from './type';

// NOTE: Thumbnailで利用するアスペクト比はこちらに追加していきます
export const ASPECT_RATIO_CLASS_NAME: Record<AspectRatio, string> = {
  '38/25': 'tw-aspect-[38/25]', // 38:25 = 1.52 → 例: 760×500,  220×144.7368...
  '19/10': 'tw-aspect-[19/10]', // 19:10 = 1.90 → 例: 760×400,  700x368.4...
};

export const ROUND_SIZE_CLASS_NAME: Record<ROUND_SIZE, string> = {
  md: 'tw-rounded-md',
  lg: 'tw-rounded-lg',
};
