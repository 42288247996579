import type { IconBaseProps } from '@/components/icons/type';

export const IconQuestion = ({ boxSize = '2em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#90CFFF" />
      <path
        d="M20.8354 23.0191L20.0022 23.8281C19.8967 23.9351 19.7526 23.9969 19.6011 24.0001C19.4439 23.995 19.2941 23.9338 19.1794 23.8281L18.4182 23.0797C18.3839 23.039 18.3417 23.0055 18.294 22.9812C18.2463 22.9568 18.1941 22.9422 18.1405 22.9381C17.4799 23.1715 16.7851 23.2979 16.0833 23.3123C15.4624 23.3492 14.8415 23.2379 14.2738 22.9878C13.706 22.7378 13.2082 22.3564 12.8228 21.8762C12.1501 20.8241 11.8466 19.5843 11.9588 18.3465V12.9558C11.8373 11.7141 12.1414 10.4681 12.8228 9.41595C13.2372 8.96979 13.7416 8.61344 14.3039 8.36964C14.8662 8.12583 15.474 8 16.0885 8C16.703 8 17.3108 8.12583 17.8731 8.36964C18.4353 8.61344 18.9398 8.96979 19.3542 9.41595C20.0327 10.4694 20.3366 11.7143 20.2182 12.9558V18.3465C20.2228 19.2552 20.1017 20.1601 19.8582 21.0367C19.8625 21.1119 19.8819 21.1856 19.9155 21.2533C19.9491 21.3211 19.996 21.3815 20.0536 21.4311L20.8148 22.1795C20.913 22.2917 20.9644 22.4362 20.9588 22.5841C20.9711 22.7389 20.9274 22.893 20.8354 23.0191ZM17.8833 12.9963C17.8833 10.8724 17.4616 10.1442 16.1245 10.1442C14.7874 10.1442 14.3245 10.8724 14.3245 12.9963V18.3263C14.3245 20.4299 14.7976 21.158 16.1245 21.158C16.2376 21.158 16.2788 21.0974 16.2788 21.0367C16.2788 20.976 16.0422 20.8547 16.0422 20.4704C16.0394 20.3978 16.0516 20.3253 16.0781 20.2575C16.1047 20.1897 16.1449 20.128 16.1965 20.0759L17.0399 19.2668C17.092 19.2142 17.1544 19.1729 17.2234 19.145C17.2924 19.1172 17.3665 19.1036 17.4411 19.105C17.5645 19.105 17.6776 19.105 17.7599 19.105C17.8422 19.105 17.8833 19.0342 17.8833 18.9128V12.9963Z"
        fill="#614000"
      />
    </svg>
  );
};
