import { clsx } from 'clsx';

import type { ThumbnailImageProps } from './type';

import { Image } from '@/components/ui';
import { NO_IMAGE_SRC } from '@/constants';

import { ASPECT_RATIO_CLASS_NAME, ROUND_SIZE_CLASS_NAME } from './const';

export const ThumbnailImage = ({
  src,
  alt = '',
  rounded,
  aspectRatio = '38/25',
  priority,
  size,
  objectFit = 'contain',
}: ThumbnailImageProps) => {
  return (
    <div className={clsx('tw-relative tw-overflow-hidden')}>
      {src && objectFit === 'contain' && (
        <div
          className={clsx(
            'tw-absolute tw-inset-0 tw-blur-2xl tw-opacity-40 tw-z-hide'
          )}
        >
          <Image src={src} alt={''} objectFit="cover" priority size={size} />
        </div>
      )}
      <div
        className={clsx(
          'tw-w-full',
          ASPECT_RATIO_CLASS_NAME[aspectRatio],
          rounded !== undefined &&
            clsx('tw-overflow-hidden', ROUND_SIZE_CLASS_NAME[rounded])
        )}
      >
        <Image
          src={src || NO_IMAGE_SRC}
          alt={alt}
          objectFit={objectFit}
          priority={priority}
          size={size}
        />
      </div>
    </div>
  );
};
