import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';
import { Section } from 'react-headings';

import type { TopProps } from './type';

import { SidebarForSSR } from '@/components/app';
import {
  ArticleVerticalCardSlider,
  ThemedContestArticleList,
} from '@/components/features/article';
import { RectangleBanner } from '@/components/features/banner';
import { GoogleAdsense } from '@/components/features/google';
import { NotificationHeadlineCardList } from '@/components/features/notification';
import { IconHirumo12 } from '@/components/icons';
import {
  Heading,
  Image,
  ButtonLink,
  ArrowLink,
  AnchorLink,
} from '@/components/ui';
import { CONTENT_WIDTH, ROUTE } from '@/constants';
import { DATE_FORMAT } from '@/constants/date';
import { ADSENSE, ADSENSE_PUBLISHER_CIENT } from '@/constants/google';
import {
  NotificationHeadlineCardListItemFragmentDoc,
  ThemedContestArticleListArticlesFragmentDoc,
  ArticleVerticalCardSliderArticlesFragmentDoc,
  TopPageContestNewsListArticlesFragmentDoc,
  TopPageQaListArticlesFragmentDoc,
  SidebarFragmentDoc,
} from '@/graphql/generated';
import { LayoutWithSidebar } from '@/layouts';
import { formatDate } from '@/utils/date';
import { findArticleTagId } from '@/utils/features/article';

import { ContestNewsList } from './ContestNewsList';
import { FooterBanner } from './FooterBanner';
import { MainSlider } from './MainSlider';
import { PickupContestList } from './PickupContestList';
import { PremiumBanner } from './PremiumBanner';
import { QAList } from './QAList';
import { Seo } from './Seo';
import { StandardBanner } from './StandardBanner';
import { SECTION_TITLE } from './const';

export const TopPage = ({ data }: TopProps) => {
  return (
    <>
      <Seo />
      <LayoutWithSidebar
        sidebar={
          <SidebarForSSR
            data={filter(SidebarFragmentDoc, data)}
            className={clsx('tw-mt-6')}
          />
        }
      >
        <main className={clsx('tw-px-spContentSide md:tw-px-0 lg:tw-mt-2')}>
          <Section component={null}>
            <section>
              <div
                className={clsx('tw-h-12 tw-flex tw-items-center tw-space-x-3')}
              >
                <div className={clsx('tw-h-[26px] md:tw-h-9')}>
                  <IconHirumo12 />
                </div>
                <p className={clsx('tw-text-ui12 md:tw-text-ui13')}>
                  <span>今日は{formatDate(new Date(), DATE_FORMAT.Md)}。</span>
                  {data.contestsAggregate.aggregate != null && (
                    <span>{`${data.contestsAggregate.aggregate.count.toLocaleString()}件のコンテストを掲載中！`}</span>
                  )}
                </p>
              </div>
            </section>

            <section className={clsx('tw-hidden md:tw-block')}>
              <MainSlider
                banners={data.bannerSuperBanners}
                slideWidthRatio={1}
                spaceBetween={0}
              />
            </section>
            <section className={clsx('-tw-mx-spContentSide md:tw-hidden')}>
              <MainSlider
                banners={data.bannerSuperBanners}
                slideWidthRatio={0.86}
                spaceBetween={8}
              />
            </section>

            <section
              className={clsx('tw-mt-8 tw--mr-4', 'lg:tw-mt-12 md:tw-mx-0')}
            >
              <PremiumBanner banners={data.bannerPremiumBanners} />
            </section>

            <section className={clsx('tw-mt-10 md:tw-hidden')}>
              <AnchorLink href={ROUTE.student('all')}>
                <Image
                  src="/images/contest_for_student_button.png"
                  alt="学生向けコンテストを探す"
                  priority
                  unoptimized
                />
              </AnchorLink>
            </section>

            {data.bannerRectangleBanners.length > 0 && (
              <section
                className={clsx(
                  'tw-mt-8 tw-flex tw-justify-center md:tw-hidden'
                )}
              >
                <RectangleBanner banners={data.bannerRectangleBanners} />
              </section>
            )}

            <section className={clsx('tw-mt-12')}>
              <Section
                component={
                  <Heading type="sectionTitle">
                    {SECTION_TITLE.PICKUP_CONTESTS}
                  </Heading>
                }
              >
                <div className={clsx('tw-mt-4 md:tw-mt-8')}>
                  <PickupContestList banners={data.bannerPickupBanners} />
                </div>
              </Section>
            </section>

            {data.themeArticles.length > 0 && (
              <section className={clsx('tw-mt-12')}>
                <Section
                  component={
                    <Heading type="sectionTitle">
                      {SECTION_TITLE.THEME_CONTEST_ARTICLES}
                    </Heading>
                  }
                >
                  <div
                    className={clsx(
                      'tw-mt-4 tw--mr-4',
                      'md:tw-mt-8 md:tw-mx-0'
                    )}
                  >
                    <ThemedContestArticleList
                      articles={data.themeArticles.map((article) =>
                        filter(
                          ThemedContestArticleListArticlesFragmentDoc,
                          article
                        )
                      )}
                    />
                  </div>
                  <div className={clsx('tw-mt-6 tw-text-center')}>
                    <ButtonLink
                      theme="outline"
                      href={ROUTE.articleListByTag(
                        findArticleTagId(
                          data.articleTags,
                          SECTION_TITLE.THEME_CONTEST_ARTICLES
                        ) ?? -1,
                        {}
                      )}
                      isBlock={false}
                    >
                      もっとみる
                    </ButtonLink>
                  </div>
                </Section>
              </section>
            )}

            {data.seriesArticles.length > 0 && (
              <section className={clsx('tw-mt-12')}>
                <Section
                  component={
                    <Heading type="sectionTitle">
                      {SECTION_TITLE.SERIES_ARTICLES}
                    </Heading>
                  }
                >
                  <div
                    className={clsx(
                      'tw-mt-4 tw--mr-4',
                      'md:tw-mt-8 md:tw-mx-0'
                    )}
                  >
                    <ArticleVerticalCardSlider
                      articles={data.seriesArticles.map((article) =>
                        filter(
                          ArticleVerticalCardSliderArticlesFragmentDoc,
                          article
                        )
                      )}
                      withTags={false}
                      imageSize={{
                        sp: `${CONTENT_WIDTH.sp / 2}px`,
                        tablet: `${CONTENT_WIDTH.tablet / 4}px`,
                        pc: `${CONTENT_WIDTH.pcMain / 4}px`,
                      }}
                      titleSize={14}
                      spTitleSize={12}
                    />
                  </div>
                  <div className={clsx('tw-mt-6 tw-text-center')}>
                    <ButtonLink
                      theme="outline"
                      href={ROUTE.articleListByTag(
                        findArticleTagId(
                          data.articleTags,
                          SECTION_TITLE.SERIES_ARTICLES
                        ) ?? -1,
                        {}
                      )}
                      isBlock={false}
                    >
                      もっとみる
                    </ButtonLink>
                  </div>
                </Section>
              </section>
            )}

            {data.contestNewsArticles.length > 0 && (
              <section className={clsx('tw-mt-12')}>
                <Section
                  component={
                    <Heading type="sectionTitle">
                      {SECTION_TITLE.CONTEST_NEWS_ARTICLES}
                    </Heading>
                  }
                >
                  <div className={clsx('tw-mt-4 md:tw-mt-8')}>
                    <ContestNewsList
                      articles={data.contestNewsArticles.map((article) =>
                        filter(
                          TopPageContestNewsListArticlesFragmentDoc,
                          article
                        )
                      )}
                      banners={data.bannerTextLinks}
                    />
                  </div>
                  <div
                    className={clsx(
                      'tw-mt-3 tw-flex tw-justify-end',
                      'md:tw-mt-5'
                    )}
                  >
                    <ArrowLink
                      path={ROUTE.articleListByTag(
                        findArticleTagId(
                          data.articleTags,
                          SECTION_TITLE.CONTEST_NEWS_ARTICLES
                        ) ?? -1,
                        {}
                      )}
                    >{`${SECTION_TITLE.CONTEST_NEWS_ARTICLES}一覧へ`}</ArrowLink>
                  </div>
                </Section>
              </section>
            )}

            {ADSENSE_PUBLISHER_CIENT && (
              <aside className={clsx('tw-mt-12')}>
                <GoogleAdsense
                  client={ADSENSE_PUBLISHER_CIENT}
                  {...ADSENSE.top}
                />
              </aside>
            )}

            {data.qaArticles.length > 0 && (
              <section className={clsx('tw-mt-12')}>
                <Section
                  component={
                    <Heading type="sectionTitle">
                      {SECTION_TITLE.CONTEST_QA_ARTICLES}
                    </Heading>
                  }
                >
                  <div className={clsx('tw-mt-4 md:tw-mt-8')}>
                    <QAList
                      articles={data.qaArticles.map((article) =>
                        filter(TopPageQaListArticlesFragmentDoc, article)
                      )}
                    />
                  </div>
                  <div className={clsx('tw-mt-6 tw-text-center')}>
                    <ButtonLink
                      theme="outline"
                      href={ROUTE.articleListByTag(
                        findArticleTagId(
                          data.articleTags,
                          SECTION_TITLE.CONTEST_QA_ARTICLES
                        ) ?? -1,
                        {}
                      )}
                      isBlock={false}
                    >
                      もっとみる
                    </ButtonLink>
                  </div>
                </Section>
              </section>
            )}

            <section className={clsx('tw-mt-12 tw--mr-4 md:tw-mx-0')}>
              <StandardBanner banners={data.bannerStandardBanners} />
            </section>

            {data.contestResultArticles.length > 0 && (
              <section className={clsx('tw-mt-12')}>
                <Section
                  component={
                    <Heading type="sectionTitle">
                      {SECTION_TITLE.CONTEST_RESULT_ARTICLES}
                    </Heading>
                  }
                >
                  <div
                    className={clsx(
                      'tw-mt-4 tw--mr-4',
                      'md:tw-mt-8 md:tw-mx-0'
                    )}
                  >
                    <ArticleVerticalCardSlider
                      articles={data.contestResultArticles.map((article) =>
                        filter(
                          ArticleVerticalCardSliderArticlesFragmentDoc,
                          article
                        )
                      )}
                      withTags={false}
                      imageSize={{
                        sp: `${CONTENT_WIDTH.sp / 2}px`,
                        tablet: `${CONTENT_WIDTH.tablet / 4}px`,
                        pc: `${CONTENT_WIDTH.pcMain / 4}px`,
                      }}
                      titleSize={14}
                      spTitleSize={12}
                    />
                  </div>
                  <div className={clsx('tw-mt-6 tw-text-center')}>
                    <ButtonLink
                      theme="outline"
                      href={ROUTE.articleListByTag(
                        findArticleTagId(
                          data.articleTags,
                          SECTION_TITLE.CONTEST_RESULT_ARTICLES
                        ) ?? -1,
                        {}
                      )}
                      isBlock={false}
                    >
                      もっとみる
                    </ButtonLink>
                  </div>
                </Section>
              </section>
            )}

            {data.notifications.length !== 0 && (
              <section className={clsx('tw-mt-12')}>
                <Section
                  component={
                    <Heading type="sectionTitle">
                      {SECTION_TITLE.NOTIFICATION}
                    </Heading>
                  }
                >
                  <div className={clsx('tw-mt-4 md:tw-mt-8')}>
                    <NotificationHeadlineCardList
                      items={data.notifications.map((notification) =>
                        filter(
                          NotificationHeadlineCardListItemFragmentDoc,
                          notification
                        )
                      )}
                    />
                  </div>
                  <div
                    className={clsx(
                      'tw-mt-3 tw-flex tw-justify-end',
                      'md:tw-mt-5'
                    )}
                  >
                    <ArrowLink
                      path={ROUTE.notificationList({})}
                    >{`${SECTION_TITLE.NOTIFICATION}一覧へ`}</ArrowLink>
                  </div>
                </Section>
              </section>
            )}

            <section className={clsx('tw-mt-12')}>
              <Section
                component={
                  <Heading type="sectionTitle">
                    {SECTION_TITLE.RECOMMENDED}
                  </Heading>
                }
              >
                <div className={clsx('tw-mt-4 md:tw-mt-8')}>
                  <FooterBanner banners={data.bannerFooterBanners} />
                </div>
              </Section>
            </section>
          </Section>
        </main>
      </LayoutWithSidebar>
    </>
  );
};
