type Theme = {
  textColor: 'tw-text-black' | 'tw-text-white';
  bgImageSrc: string;
};

/**
 * 背景とテキストの色セット
 */
export const THEME_SETS: Array<Theme> = [
  {
    textColor: 'tw-text-black',
    bgImageSrc: 'tw-bg-[url(/images/theme_01.svg)]',
  },
  {
    textColor: 'tw-text-white',
    bgImageSrc: 'tw-bg-[url(/images/theme_02.svg)]',
  },
  {
    textColor: 'tw-text-black',
    bgImageSrc: 'tw-bg-[url(/images/theme_03.svg)]',
  },
  {
    textColor: 'tw-text-white',
    bgImageSrc: 'tw-bg-[url(/images/theme_04.svg)]',
  },
];
