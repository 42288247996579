import { clsx } from 'clsx';

import type { SliderProps } from './types';

export const Slider = ({
  className,
  items,
  offset,
  view,
  spaceBetween,
}: SliderProps) => {
  const itemWidth = `calc((100% - ${spaceBetween * (view - 1)}px) / ${view})`;
  return (
    <div
      className={clsx(
        'tw-flex tw-w-full tw-overflow-x-scroll tw-hidden-scrollbar md:tw-overflow-hidden',
        className
      )}
      style={{ gap: `${spaceBetween}px` }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={clsx(
            'tw-shrink-0 tw-transition tw-transform tw-duration-300 tw-ease-out'
          )}
          style={{
            width: itemWidth,
            transform:
              items.length - offset < view
                ? `translateX(calc(-${
                    items.length - view
                  } * (100% + ${spaceBetween}px))`
                : `translateX(calc(-${offset} * (100% + ${spaceBetween}px))`,
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
