import { clsx } from 'clsx';

import type { ArrowLinkProps } from './type';

import { IconOvalArrowRight } from '@/components/icons';
import { AnchorLink } from '@/components/ui';

export const ArrowLink = ({ path = '#', children }: ArrowLinkProps) => {
  return (
    <AnchorLink
      href={path}
      className={clsx('tw-flex tw-items-center tw-gap-x-1')}
    >
      <>
        <span className="tw-text-ui13">{children}</span>
        <IconOvalArrowRight />
      </>
    </AnchorLink>
  );
};
