import { clsx } from 'clsx';

import type { PickupContestListProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import {
  AnchorLink,
  HorizontalCard,
  DeadlineLabel,
  VerticalCard,
  PRLabel,
} from '@/components/ui';
import { CONTENT_WIDTH, NO_IMAGE_SRC } from '@/constants';
import { isExternal } from '@/utils/features/banner';

export const PickupContestList = ({ banners }: PickupContestListProps) => {
  return (
    <>
      <ul className={clsx('md:tw-hidden tw-space-y-4')}>
        {banners
          .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
          .map((banner) => (
            <li key={banner.id}>
              <AnchorLink
                {...bannerAttributes({ id: banner.id, type: banner.type })}
                href={banner.url}
                target={isExternal(banner.url) ? '_blank' : '_self'}
              >
                <HorizontalCard
                  title={banner.lead ?? ''}
                  imageSrc={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                  imageSize={{ sp: '90px' }}
                  bottomSection={
                    <div
                      className={clsx('tw-flex tw-items-center tw-space-x-1')}
                    >
                      {isExternal(banner.url) && <PRLabel />}
                      {banner.deadline != null && (
                        <DeadlineLabel
                          deadlineDate={new Date(banner.deadline)}
                        />
                      )}
                    </div>
                  }
                  titleSize={12}
                />
              </AnchorLink>
            </li>
          ))}
      </ul>

      <ul className={clsx('tw-hidden md:tw-grid tw-grid-cols-4 tw-gap-5')}>
        {banners
          .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
          .map((banner) => (
            <li key={banner.id}>
              <AnchorLink
                {...bannerAttributes({ id: banner.id, type: banner.type })}
                href={banner.url}
                target={isExternal(banner.url) ? '_blank' : '_self'}
              >
                <VerticalCard
                  title={banner.lead ?? ''}
                  imageSrc={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                  imageSize={{
                    tablet: `${CONTENT_WIDTH.tablet / 4}px`,
                    pc: `${CONTENT_WIDTH.pcMain / 4}px`,
                  }}
                  bottomSection={
                    <div
                      className={clsx('tw-flex tw-items-center tw-space-x-1')}
                    >
                      {isExternal(banner.url) && <PRLabel />}
                      {banner.deadline != null && (
                        <DeadlineLabel
                          deadlineDate={new Date(banner.deadline)}
                        />
                      )}
                    </div>
                  }
                  titleSize={14}
                />
              </AnchorLink>
            </li>
          ))}
      </ul>
    </>
  );
};
