import { clsx } from 'clsx';
import { useMemo } from 'react';

import type { ThemedContestArticleListProps } from './type';

import { THEME_SETS } from '@/components/features/article/ThemedContestArticleList/const';
import { AnchorLink, CardSlider } from '@/components/ui';
import { ROUTE } from '@/constants';

export const ThemedContestArticleList = ({
  articles,
}: ThemedContestArticleListProps) => {
  // NOTE: sliderの items の箇所でindexを拾うと値がおかしくなるので前もってサムネイルの情報を与えておく
  const themeMergedArticles = useMemo(() => {
    return articles.map((article, index) => {
      const targetThemeIndex = index % THEME_SETS.length;

      return {
        ...article,
        ...THEME_SETS[targetThemeIndex],
      };
    });
  }, [articles]);

  return (
    <CardSlider
      items={themeMergedArticles
        .flatMap((a) => (a.id ? { ...a, id: a.id } : []))
        .map((article) => (
          <div key={article.id} className={clsx('tw-rounded-md')}>
            <AnchorLink href={ROUTE.articleDetail(article.id)}>
              <div className={clsx('tw-relative')}>
                <div
                  className={clsx(
                    'tw-aspect-[175/196]',
                    'tw-flex tw-items-center tw-justify-center tw-px-3',
                    'tw-rounded-lg tw-overflow-hidden',
                    article.bgImageSrc,
                    'tw-bg-cover'
                  )}
                >
                  <div
                    className={clsx(
                      'tw-text-ui16 md:tw-text-ui20 tw-font-bold tw-text-center',
                      'tw-line-clamp-4 md:tw-line-clamp-5',
                      article.textColor
                    )}
                  >
                    {article.listView?.lead ?? article.title}
                  </div>
                </div>
              </div>
            </AnchorLink>
          </div>
        ))}
      spView={3.2}
      spSpaceBetween={10}
      view={4}
      spaceBetween={20}
      isThumbnailOnly
    />
  );
};
