import type { ContestNewsListProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import { AnchorLink, HeadlineCard, NewLabel, PRLabel } from '@/components/ui';
import { DATE_FORMAT, ROUTE } from '@/constants';
import { formatDate, isWithinPastDays } from '@/utils/date';
import { isExternal } from '@/utils/features/banner';

export const ContestNewsList = ({
  articles,
  banners,
}: ContestNewsListProps) => {
  const items = articles.flatMap((article) =>
    article.id
      ? {
          ...article,
          id: article.id,
          title: article.listView?.lead ?? article.title ?? '',
          publishedAt: formatDate(article.publishedAt, DATE_FORMAT.yyyyMMdd),
          isPastDays: isWithinPastDays(article.publishedAt, 7),
        }
      : []
  );

  return (
    <ul>
      {banners
        .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
        .map((banner) => (
          <li key={banner.id}>
            <AnchorLink
              {...bannerAttributes({ id: banner.id, type: banner.type })}
              href={banner.url}
              target={isExternal(banner.url) ? '_blank' : '_self'}
            >
              <HeadlineCard
                title={banner.lead ?? ''}
                label={<PRLabel />}
                publishedAt={
                  formatDate(banner.period?.start, DATE_FORMAT.yyyyMMdd) ?? ''
                }
              />
            </AnchorLink>
          </li>
        ))}
      {items.map((item) => (
        <li key={item.id}>
          <AnchorLink href={ROUTE.articleDetail(item.id)}>
            <HeadlineCard
              {...item}
              label={item.isPastDays && <NewLabel />}
              publishedAt={item.publishedAt ?? ''}
            />
          </AnchorLink>
        </li>
      ))}
    </ul>
  );
};
