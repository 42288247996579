import { clsx } from 'clsx';

import type { DeadlineLabelProps } from './type';

import { DATE_FORMAT } from '@/constants/date';
import { formatDate } from '@/utils/date';

export const DeadlineLabel = ({ deadlineDate }: DeadlineLabelProps) => {
  return (
    <span
      className={clsx(
        'tw-h-4 tw-px-1 tw-pb-px tw-inline-flex tw-items-center',
        'tw-bg-gray-600 tw-text-white tw-text-ui11 tw-rounded-sm'
      )}
    >{`${
      deadlineDate.getFullYear() === new Date().getFullYear()
        ? formatDate(deadlineDate, DATE_FORMAT.Md)
        : formatDate(deadlineDate, DATE_FORMAT.yyyyMd)
    } 締切`}</span>
  );
};
