import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';

import type { ArticleVerticalCardProps } from './type';

import { ArticleTagList } from '@/components/features/article';
import { AnchorLink, VerticalCard, NewLabel } from '@/components/ui';
import { DATE_FORMAT, NO_IMAGE_SRC, ROUTE } from '@/constants';
import { ArticleTagListArticlesFragmentDoc } from '@/graphql/generated';
import { formatDate } from '@/utils/date';
import { checkIsNewArticle } from '@/utils/features/article';

export const ArticleVerticalCard = ({
  article,
  withTags = true,
  ...props
}: ArticleVerticalCardProps) => {
  const thumbnailUrl =
    article.listView?.thumbnail?.file?.url ??
    article.keyVisual?.file?.url ??
    NO_IMAGE_SRC;

  if (article.id == null) {
    throw new Error('存在しない記事です');
  }

  return (
    <div>
      <AnchorLink href={ROUTE.articleDetail(article.id)}>
        <VerticalCard
          {...props}
          title={article.listView?.lead ?? ''}
          imageSrc={thumbnailUrl}
          bottomSection={
            <div className={clsx('tw-flex tw-items-center tw-gap-x-1.5')}>
              {article.publishedAt != null && (
                <time className={clsx('tw-text-ui12 tw-text-gray-600')}>
                  {formatDate(article.publishedAt, DATE_FORMAT.yyyyMMdd)}
                </time>
              )}
              {checkIsNewArticle(article.publishedAt) && <NewLabel />}
            </div>
          }
        />
      </AnchorLink>
      {withTags ? (
        <ArticleTagList
          article={filter(ArticleTagListArticlesFragmentDoc, article)}
          wrapperClassName="tw-mt-3"
        />
      ) : undefined}
    </div>
  );
};
