import { clsx } from 'clsx';

import type { NavigationButtonProps } from './type';

export const NavigationButton = ({
  onClick,
  className,
}: NavigationButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'tw-w-10 tw-h-10 tw-rounded-circle tw-cursor-pointer tw-select-none',
        'tw-bg-white hover:tw-bg-primary tw-opacity-90 hover:tw-opacity-100',
        'tw-transition-colors tw-ease-out',
        'tw-flex tw-items-center tw-justify-center',
        className
      )}
    >
      <span className={clsx('tw-w-2 tw-h-2 tw-border-b tw-border-black')} />
    </button>
  );
};
