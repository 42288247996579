export const SECTION_TITLE = {
  PICKUP_CONTESTS: 'ピックアップ公募',
  THEME_CONTEST_ARTICLES: 'テーマ別公募',
  SERIES_ARTICLES: '投稿する',
  CONTEST_NEWS_ARTICLES: '公募ニュース',
  CONTEST_QA_ARTICLES: '公募Q&A',
  CONTEST_RESULT_ARTICLES: '結果発表',
  NOTIFICATION: 'お知らせ',
  RECOMMENDED: '編集部おすすめ',
};
