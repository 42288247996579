import clsx from 'clsx';
import React, { useState } from 'react';

import type { CardSliderProps } from './type';

import { Slider } from '@/components/ui/slider/CardSlider/Slider';

import { NavigationButton } from './NavigationButton';

const commonArrowStype = clsx(
  'tw-hidden md:tw-block tw-w-[40px]',
  'tw-absolute tw-z-docked -tw-translate-y-1/2 tw-top-1/2 tw-justify-between tw-items-center'
);

export const CardSlider: React.FC<CardSliderProps> = ({
  items,
  view,
  spView = view - 0.7,
  spaceBetween = 20,
  spSpaceBetween = 4,
  className,
}) => {
  const [offset, setOffset] = useState(0);

  const handlePrev = () => setOffset(offset - Math.floor(view));
  const handleNext = () => setOffset(offset + Math.floor(view));

  return (
    <div className={clsx('tw-relative', className)}>
      <Slider
        className={clsx('tw-hidden md:tw-flex')}
        items={items}
        offset={offset}
        spaceBetween={spaceBetween}
        view={view}
      />
      <Slider
        className={clsx('md:tw-hidden')}
        items={items}
        offset={offset}
        spaceBetween={spSpaceBetween}
        view={spView}
      />

      <div
        className={clsx(
          'tw-transition-opacity tw-duration-300 tw-linear -tw-translate-x-1/2',
          offset === 0 && 'tw-pointer-events-none tw-opacity-0',
          commonArrowStype
        )}
      >
        <NavigationButton
          onClick={handlePrev}
          className={clsx(
            'child:tw-border-l child:tw-translate-x-1/4 child:tw-rotate-45'
          )}
        />
      </div>

      <div
        className={clsx(
          'tw-transition-opacity tw-duration-300 tw-linear tw-right-0 tw-translate-x-1/2',
          items.length - offset <= view &&
            'tw-pointer-events-none tw-opacity-0',
          commonArrowStype
        )}
      >
        <NavigationButton
          onClick={handleNext}
          className={clsx(
            ' child:tw-border-r child:-tw-translate-x-1/4 child:-tw-rotate-45'
          )}
        />
      </div>
    </div>
  );
};
