import { clsx } from 'clsx';
import { useMemo } from 'react';

import type { ArticleTagListProps } from './type';

import { AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import { stringifyUrl } from '@/utils/url';

export const ArticleTagList = ({
  article: { category, subcategory, taggings },
  wrapperClassName,
}: ArticleTagListProps) => {
  const displayTagNames = useMemo(() => {
    const tags = taggings
      .flatMap(({ tag }) => tag ?? [])
      .map((tag) => ({
        id: tag.id,
        name: tag.name,
        href:
          stringifyUrl(ROUTE.articleListByTag(tag.id ?? -1, {})) ?? undefined,
      }));

    return [
      category?.name
        ? {
            ...category,
            href:
              stringifyUrl(
                ROUTE.articleListByCategory(category?.id ?? -1, {})
              ) ?? undefined,
          }
        : undefined,
      subcategory?.name
        ? {
            ...subcategory,
            href:
              stringifyUrl(
                ROUTE.articleListBySubCategory(subcategory?.id ?? -1, {
                  categoryId: category?.id ?? -1,
                })
              ) ?? undefined,
          }
        : undefined,
      ...tags,
    ].flatMap((v) => (v !== undefined && v.href !== undefined ? [v] : []));
  }, [category, subcategory, taggings]);

  return (
    <>
      {displayTagNames.length > 0 ? (
        <ul
          className={clsx(
            'tw-flex tw-flex-wrap tw-items-center tw-gap-2',
            wrapperClassName
          )}
        >
          {displayTagNames.map((tag) => (
            <li
              key={tag.id}
              className={clsx(
                'tw-inline-block tw-text-ui10 tw-px-[5px] tw-py-[3px] tw-bg-gray-100 tw-rounded-full',
                'md:tw-text-ui12 md:tw-px-2 md:tw-py-1.5'
              )}
            >
              <AnchorLink href={tag.href || '#'}>{tag.name}</AnchorLink>
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};
