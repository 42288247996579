import { clsx } from 'clsx';

import type { StandardBannerProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import {
  AnchorLink,
  CardSlider,
  DeadlineLabel,
  PRLabel,
  VerticalCard,
} from '@/components/ui';
import { CONTENT_WIDTH, NO_IMAGE_SRC } from '@/constants';
import { isExternal } from '@/utils/features/banner';

export const StandardBanner = ({ banners }: StandardBannerProps) => {
  return (
    <CardSlider
      items={banners
        .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
        .map((banner) => (
          <div key={banner.id} className={clsx('tw-rounded-md')}>
            <AnchorLink
              {...bannerAttributes({ id: banner.id, type: banner.type })}
              href={banner.url}
              target={isExternal(banner.url) ? '_blank' : '_self'}
            >
              <VerticalCard
                title={banner.lead ?? ''}
                imageSrc={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                imageSize={{
                  sp: `${CONTENT_WIDTH.sp / 2}px`,
                  tablet: `${CONTENT_WIDTH.tablet / 4}px`,
                  pc: `${CONTENT_WIDTH.pcMain / 4}px`,
                }}
                bottomSection={
                  <div className={clsx('tw-flex tw-items-center tw-space-x-1')}>
                    {isExternal(banner.url) && <PRLabel />}

                    {banner.deadline != null && (
                      <DeadlineLabel deadlineDate={new Date(banner.deadline)} />
                    )}
                  </div>
                }
                titleSize={14}
                spTitleSize={12}
              />
            </AnchorLink>
          </div>
        ))}
      spView={2.3}
      spSpaceBetween={10}
      view={4}
      spaceBetween={20}
    />
  );
};
