import { clsx } from 'clsx';

export const PRLabel = () => {
  return (
    <span
      className={clsx(
        'tw-h-4 tw-px-1 tw-pb-px tw-inline-flex tw-items-center',
        'tw-text-ui11 tw-text-gray-600',
        'tw-border tw-border-gray-600 tw-rounded-sm'
      )}
    >
      PR
    </span>
  );
};
